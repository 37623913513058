@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 20px;
	width: 100%;
}

.work-image {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	margin-right: 15px;
}

.work-info {
	flex: 1;
}

.work-title-row {
	display: flex;
	justify-content: space-between;
}

.work-title {
	font-size: 18px;
	font-weight: 700;
	color: var(--secondary-color);
}

.work-subtitle {
	font-size: 14px;
	color: var(--secondary-color);
	margin-top: 2px;
}

.work-duration {
	font-size: 14px;
	color: var(--tertiary-color);
}

.work-bullets {
	width: 100%;
	padding-left: 0.8rem;
	margin-top: 10px;
	list-style-type: disc;
}

.work-bullets li {
	margin-bottom: 5px;
	font-size: 14px;
	color: var(--secondary-color);
}
